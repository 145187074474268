import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import SvgIcon from '@/components/share/SvgIcon.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { useAuthStore } from '@/hook/useAuthStore';
import QRCodeVue3 from 'qrcode-vue3';
export default defineComponent({
  name: 'Invitation',
  components: {
    SvgIcon: SvgIcon,
    HeaderComponent: Header,
    FooterComponent: Footer,
    QRCodeVue3: QRCodeVue3
  },
  setup: function setup() {
    var store = useStore();
    var copyEle = ref();
    var invitationCode = computed(function () {
      return store.state.user.levelInfo.invitation_code;
    });
    var isLoggedIn = useAuthStore().isLoggedIn;
    var invitationLink = computed(function () {
      return "".concat(window.location.href.replace('invitation', 'register'), "?invitationCode=").concat(store.state.user.levelInfo.invitation_code);
    });
    onMounted(function () {
      store.dispatch('user/userLevel');
    });

    var copy = function copy() {
      try {
        navigator.clipboard.writeText(invitationLink.value);
        copyEle.value.classList.add('active');
        setTimeout(function () {
          copyEle.value.classList.remove('active');
        }, 2001);
      } catch (error) {
        console.log('err', error);
      }
    };

    return {
      copy: copy,
      copyEle: copyEle,
      invitationCode: invitationCode,
      isLoggedIn: isLoggedIn,
      invitationLink: invitationLink
    };
  }
});